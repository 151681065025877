body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* select starting stylings ------------------------------*/
.select {
	width: 125px;
  margin-top: 8px;
}

.select-text {
	font-family: inherit;
	background-color: transparent;
  color: rgba(150, 150, 150, 1);
	width: 125px;
  font-weight: 0;
  height: 57px;
	padding: 10px 10px 10px 10px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid rgba(200, 200, 200, 1);
}

.select-text:hover {
	outline: none;
  border: 1px solid #000000;
}
/* Remove focus */
.select-text:focus {
	outline: none;
  border: 1px solid #42a5f5;
}

	/* Use custom arrow */
.select .select-text {
	appearance: none;
	-webkit-appearance:none;
  color: rgba(200, 200, 200, 1);
}

.select:after {
	position: absolute;
	top: 18px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.12);
	pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
	color: rgba(0,0,0, 0.26);
  background: white;
	font-size: 18px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
  padding: 0 5px;
	left: 10px;
	top: 12px;
	transition: 0.2s ease all;
}


/* active state */
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
	color: #42a5f5;
	top: -8px;
	transition: 0.2s ease all;
	font-size: 14px;
}

.select-text:valid:not(focus) ~ .select-label {
  	color: rgba(0,0,0, 0.26);
  background: red;
}
.select-text:valid:focus ~ .select-label {
  color: #42a5f5;
}